import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import axios from "axios";
import HomeView from "../views/HomeView.vue";
import StoreLanding from "../views/StoreLanding.vue";
import StoreMainVoucher from "../views/StoreMainVoucher.vue";
import StoreMainVoucherFollowup from "../views/StoreMainVoucherFollowup.vue";
import StoreVoucherCodeActivation from "../views/StoreVoucherCodeActivation.vue";
import StoreVoucherCodePreview from "../views/StoreVoucherCodePreview.vue";
import StoreVoucherDashboard from "../views/StoreVoucherDashboard.vue";
import StoreVoucherExpired from "../views/StoreVoucherExpired.vue";
import StoreVoucherFinisher from "../views/StoreVoucherFinisher.vue";
import StoreVoucherShopping from "../views/StoreVoucherShopping.vue";
import store from "../store/index";
import { uuid } from "vue-uuid";

const routeNames = [
  {
    id: 1,
    route: "",
    name: "landing",
  },
  {
    id: 2,
    route: "/main",
    name: "main",
  },
  {
    id: 3,
    route: "/dashboard",
    name: "dashboard",
  },
  {
    id: 4,
    route: "/shopping",
    name: "shopping",
  },
  {
    id: 5,
    route: "/activation",
    name: "activation",
  },
  {
    id: 6,
    route: "/codepreview",
    name: "codepreview",
  },
  {
    id: 7,
    route: "/finish",
    name: "finish",
  },
  {
    id: 8,
    route: "/expired",
    name: "expired",
  },
];

const routes: Array<RouteRecordRaw> = [
  {
    path: "/:storeName",
    name: "storeLanding",
    component: StoreLanding,
  },
  {
    path: "/:storeName/followup",
    name: "storeMainVoucherFollowup",
    component: StoreMainVoucherFollowup,
  },
  {
    path: "/:storeName/main",
    name: "store",
    component: StoreMainVoucher,
  },
  {
    path: "/:storeName/dashboard",
    name: "storeVoucherDashboard",
    component: StoreVoucherDashboard,
  },
  {
    path: "/:storeName/shopping",
    name: "storeVoucherShopping",
    component: StoreVoucherShopping,
  },
  {
    path: "/:storeName/activation",
    name: "storeVoucherCodeActivation",
    component: StoreVoucherCodeActivation,
  },
  {
    path: "/:storeName/codepreview",
    name: "storeVoucherCodePreview",
    component: StoreVoucherCodePreview,
  },
  {
    path: "/:storeName/finish",
    name: "storeVoucherFinisher",
    component: StoreVoucherFinisher,
  },
  {
    path: "/:storeName/expired",
    name: "storeVoucherExpired",
    component: StoreVoucherExpired,
  },
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
];

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.afterEach(() => {
  document.body.scrollTo({ top: 1 });
  window.scrollTo({ top: 1 });
});

export async function checkCurrentPathAgainstStores() {
  if (store.state.currentProgress.step === "VOUCHER_EXPIRED") return;
  // Perform login to get JWT token
  //@todo: Maybe replace regular login with permanent token to save one request
  await store
    .dispatch("fetchApiToken")
    .then(async function () {
      const jwtToken = store.state.apiToken.token;
      // Make API call with JWT token
      await axios
        .get(process.env.VUE_APP_BACKEND_URL + "/api/stores?page=1", {
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
        })
        .then(async function (response) {
          const stores = response.data;
          const currentPath = window.location.pathname.slice(1);
          const currentPathParts = splitPath(currentPath);
          const matchingStore = stores.find(
            (singleStore: { slug: string }) =>
              singleStore.slug === currentPathParts[0]
          );
          if (matchingStore) {
            await axios
              .get(
                process.env.VUE_APP_BACKEND_URL +
                  `/api/stores/${matchingStore.slug}`,
                {
                  headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${jwtToken}`,
                  },
                }
              )
              .then(function (response) {
                if (response.data) {
                  let newInfo: any;
                  store.dispatch("setCurrentStore", response.data);
                  store.dispatch("setExpiry");
                  if (null !== store.state.currentProgress.step) {
                    if (0 === store.state.tracking.data.id)
                      newInfo = {
                        path: "init",
                        sessionId: uuid.v1(),
                        initialTimestamp: Math.floor(
                          new Date().getTime() / 1000
                        ),
                        store: `/api/stores/${response.data.slug}`,
                      };
                    else newInfo = {};
                    store.dispatch("stashTrackingInfo", newInfo);
                  } else {
                    newInfo = {
                      path: "init",
                      sessionId: uuid.v1(),
                      initialTimestamp: Math.floor(new Date().getTime() / 1000),
                      store: `/api/stores/${response.data.slug}`,
                    };
                    store.dispatch("stashTrackingInfo", newInfo);
                  }
                }
              })
              .catch(function (error) {
                console.log("error fetching specific store");
                console.log(error);
                store.dispatch("setErrorCode", "ERROR_URL");
                router.push("/");
              });
          } else {
            console.log("route not matching any store");
            // store slug not resolved - redirect to home/generic
            router.push("/");
          }
        })
        .catch(function (error) {
          console.log("error fetching all stores");
          console.log(error);
          store.dispatch("setErrorCode", "ERROR_API");
          router.push("/");
        });
    })
    .catch(function (error) {
      console.log("error during login");
      console.log(error);
      store.dispatch("setErrorCode", "ERROR_API");
      router.push("/");
    });
}

const splitPath = (path: string) => {
  const parts = path.split("/");
  if (parts.length >= 2) {
    const firstPart = parts.shift(); // Das erste Element entfernen
    const remainingPath = parts.join("/"); // Die verbleibenden Teile wieder zusammenfügen
    return [firstPart, remainingPath];
  } else {
    // Wenn es weniger als 2 Teile gibt, gib den gesamten Pfad als erstes Element zurück
    return [path, ""];
  }
};

export function determineCurrentStep() {
  const currentPathParts = splitPath(window.location.pathname.slice(1));
  if (null !== store.state.currentProgress.step) {
    const matchingStep = routeNames.find((routeName) => {
      return routeName.name === store.state.currentProgress.step;
    });
    if (typeof matchingStep !== "undefined" && null !== matchingStep.name) {
      if (currentPathParts[1] !== matchingStep.name) {
        router.push("/" + currentPathParts[0] + matchingStep?.route);
      }
    }
  } else {
    if (currentPathParts[1]) router.push("/" + currentPathParts[0]);
  }
  let matchingStep = routeNames.find((routeName) => {
    return routeName.id === 1;
  });
  if (null !== store.state.currentProgress) {
    if (store.state.currentProgress.initialDiscount > 0) {
      matchingStep = routeNames.find((routeName) => {
        return routeName.id === 2;
      });
    }
    if (store.state.currentProgress.addedDiscounts.length > 0) {
      matchingStep = routeNames.find((routeName) => {
        return routeName.id === 3;
      });
    }
    if (
      null !== store.state.tracking.data.finalDiscount &&
      store.state.tracking.data.finalDiscount > 0
    ) {
      matchingStep = routeNames.find((routeName) => {
        return routeName.id === 4;
      });
    }
    if (store.state.currentProgress.step === "activation") {
      matchingStep = routeNames.find((routeName) => {
        return routeName.id === 5;
      });
    }
    if (
      null !== store.state?.tracking?.data?.finalTimestamp &&
      store.state.tracking.data.finalTimestamp > 0
    ) {
      const expiry = store.state?.currentProgress?.stepData?.expiryTime;
      if (
        typeof expiry !== "undefined" &&
        null !== expiry &&
        expiry > Math.floor(Date.now() / 1000)
      ) {
        matchingStep = routeNames.find((routeName) => {
          return routeName.id === 6;
        });
      } else {
        matchingStep = routeNames.find((routeName) => {
          return routeName.id === 7;
        });
      }
    }
  }
}

export default router;
